<template>
    <section class="mx-3 mt-2">
        <div class="row mx-0 justify-center py-3 bg-white br-12 py-3">
            <div class="col-4">
                <el-select
                v-model="id_producto"
                filterable
                remote
                reserve-keyword
                placeholder="Buscar producto"
                :remote-method="remoteMethod"
                class="w-100"
                size="small"
                >
                    <el-option
                    v-for="item in opciones"
                    :key="item.id"
                    :label="`${item.sku} - ${item.nombre} - ${item.barcode} - ${item.presentacion}`"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="small"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                popper-class="date-picker-edit-popper"
                type="daterange"
                unlink-panels
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                />
            </div>
            <div class="col-auto">
                <div class="btn-buscar text-white d-middle-center px-3 cr-pointer" @click="informe">
                    Buscar
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="max-height:calc(100vh - 67px);">
            <div class="row mx-0 justify-center py-3">
                <div class="col-3">
                    <div class="bg-white card-info br-12 p-2">
                        <p class="text-general f-30 f-600 text-center">{{ agregarSeparadoresNumero(datos.unidades) }}</p>
                        <p class="text-general f-18 mt-2 text-center">
                            <i class="icon-package-variant-closed f-20" />
                            Unidades
                        </p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="bg-white card-info br-12 p-2">
                        <p class="text-general f-30 f-600 text-center">{{ agregarSeparadoresNumero(datos.pedidos) }}</p>
                        <p class="text-general f-18 mt-2 text-center">
                            <i class="icon-pedidos f-18" />
                            Pedidos
                        </p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="bg-white card-info br-12 p-2">
                        <p class="text-general f-30 f-600 text-center">{{ separadorNumero(datos.ventas) }}</p>
                        <p class="text-general f-20 mt-2 text-center">
                            <i class="icon-cash" />
                            Valor
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import Productos from "~/services/productos/productos_stocks";
export default {
    data(){
        return{
            id_producto: null,
            opciones: [],
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            datos: {
                unidades: 0,
                pedidos: 0,
                ventas: 0,
            }
        }
    },
    computed:{
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis_navbar',
        }),
    },
    watch: {
        id_moneda(){
            this.informe()
        }
    },
    methods: {
        async remoteMethod(query){
            try {
                if(query.length > 2){
                    const busqueda = async() => {
                        let params = {
                            id_cedis: this.id_cedis,
                            query
                        }

                        const {data} = await Productos.buscadorProductos(params)
                        this.opciones = data.productos
                    }
                    await this.delay(busqueda)
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async informe(){
            try {
                const params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_producto: this.id_producto
                }
                const {data} = await  Productos.informe(params)

                this.datos = data.productos

            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.btn-buscar{
    height: 32px;
    background-color: var(--color-general) !important;
    border-radius: 6px;
}
.card-info{
    width: 100%;
    height: 100px;
}
</style>
