import axios from 'axios'

const API = 'productos-stocks'

const Productos = {

    listarGraficas(id_producto, params={}){
        return axios.get(`${API}/${id_producto}/graficas`,{params})
    },
    agregarStock(payload = {}){
        return axios.post(`${API}/stocks`,payload)
    },
    info_producto_stock(id_producto, params = {}){
        return axios.get(`${API}/${id_producto}/info-producto-stock`,{params})
    },
    listaStockRetiros(params = {}){
        return axios.get(`${API}/stocks-retiros`,{params})
    },
    retiroTienda(payload){
        return axios.put(`retiro/producto/tienda`,payload)
    },
    retiroCedis(payload){
        return axios.put(`retiro/producto/cedis`,payload)
    },
    devolucion(payload){
        return axios.put(`devolucion/tienda`,payload)
    },
    traslado(payload){
        return axios.put(`traslado/cedis`,payload)
    },
    buscadorProductos(params){
        return axios.get(`productos/inventario/buscar`,{params})
    },
    importarStock(form){
        return axios.post(`productos/inventario/importar`,form)
    },
    getImportacion(params){
        return axios.get(`productos/inventario/importar`,{params})
    },
    remplazarStock(data){
        return axios.post(`productos/inventario/importar/update`,data)
    },
    realizarImportacion(id_importacion, params){
        return axios.put(`productos/inventario/importar/${id_importacion}/confirmar`, params)
    },
    updateRegistroTemporal(id_temporal, form){
        return axios.put(`productos/inventario/temp/${id_temporal}/update`, form)
    },
    deleteRegistro(id_temporal){
        return axios.delete(`productos/inventario/temp/${id_temporal}/delete`)
    },
    updateProveedorGeneral(form){
        return axios.put(`productos/inventario/proveedor/update`,form)
    },
    updateComentarioGeneral(form){
        return axios.put(`productos/inventario/comentario/update`,form)
    },
    descartarImportacion(id_importacion){
        return axios.delete(`productos/inventario/importar/${id_importacion}/delete`)
    },
    getHistorialImportaciones(id_cedis){
        return axios.get(`productos/inventario/${id_cedis}/historial`)
    },
    anularImportacion(form){
        return axios.put(`productos/inventario/importar/${form.id_importacion}/anular`,form)
    },
    getDetalleImportacion(id_importacion){
        return axios.get(`productos/inventario/importar/${id_importacion}/detalle`)
    },

    informe(params){
        return axios.get(`productos/informe-buscar`, { params });
    },



}

export default Productos

